import React, { useState } from 'react';
import {
	Container,
	Grid,
	FormControl,
	FormLabel,
	FormGroup,
	RadioGroup,
	FormControlLabel,
	Radio,
	TextField,
	Checkbox,
	Button,
	FormHelperText,
} from '@mui/material';

const referenceOptions = [
	{ value:'brescia', label:'BRESCIA' },
	{ value:'leno', label:'LENO' },
	{ value:'montichiari', label:'MONTICHIARI' },
	{ value:'verolanuova', label:'VEROLANUOVA' },
	{ value:'chiari', label:'CHIARI' },
	{ value:'orzinuovi', label:'ORZINUOVI' },
	{ value:'lonato', label:'LONATO' },
	{ value:'darfo', label:'DARFO' },
	{ value:'dipendente', label:'DIPENDENTE' },
	{ value:'altro', label:'OSPITI' },
]
const attendOptions = [
	{ value:'assembly', label: 'ASSEMBLEA' },
	{ value:'all', label: 'ASSEMBLEA + CENA' },
]
const menuOptions = [
	{ value:'meat', label: 'SPIEDO' },
	{ value:'veg', label: 'PIATTO VEGETARIANO' },
]

export const FieldText = ({ formData, formErrors, label, name, handleChange, type }) => {
	const value = formData?.[name] || ''
	const error = formErrors.find?.(error => error.property === name)?.error || ''
	return (
		<TextField
			label={label}
			name={name}
			value={value}
			onChange={handleChange}
			type={type || undefined}
			fullWidth
			error={Boolean(error)} // Checks if there's an error for this field
			helperText={error || ''} // Displays the error message if it exists
		/>
	)
}
  
export const FieldAuthorizeData = ({ formData, formErrors, name, handleChange }) => {
	const error = formErrors.find?.(error => error.property === name)?.error || ''
	return (
		<FormControl error={Boolean(error)} component="fieldset" sx={{ textAlign: 'left' }}>
			<FormLabel component="legend">
				Autorizzo il trattamento dei miei dati personali ai sensi del Dlgs 196 del 30 giugno 2003 e dell’art. 13 GDPR (Regolamento UE 2016/679) ai fini dell'organizzazione dell'evento. Informativa privacy
			</FormLabel>
			<FormControlLabel
				control={<Checkbox checked={formData[name] || false} onChange={handleChange} name={name} />}
				label="Accetto"
			/>
			{Boolean(error) && (
				<FormHelperText>{error}</FormHelperText>
			)}
		</FormControl>
	)
}

export const FieldRadio = ({ formData, formErrors, name, handleChange, label, options }) => {
	const value = formData?.[name] || ''
	const error = formErrors.find?.(error => error.property === name)?.error || ''
	return (
		<FormControl error={Boolean(error)} component="fieldset">
			<FormLabel component="legend">{label}</FormLabel>
			<RadioGroup
				aria-label={name}
				name={name}
				value={value}
				onChange={handleChange}
				column="true"
			>
			{options.map((option) => (
				<FormControlLabel value={option.value} control={<Radio />} label={option?.label || option.value} key={option.value} />
			))}
			</RadioGroup>
			{Boolean(error) && (
				<FormHelperText>{error}</FormHelperText>
			)}
		</FormControl>
	)
}

export const FormConferenza = ({ handleSubmit, formData, formErrors, handleChange }) => {
	return (
		<form onSubmit={handleSubmit} style={{ padding: '20px', backgroundColor: 'white', borderRadius: '8px' }}> {/* Example styling */}
			<Grid container spacing={2}> {/* Adds spacing between items */}

				<Grid item xs={12}>
					<FieldText
					label="Ragione Sociale*"
					formData={formData}
					formErrors={formErrors}
					name="businessName"
					handleChange={handleChange}
					/>
				</Grid>

				<Grid item xs={12}>
					<FieldText
					label="Codice Fiscale Azienda*"
					formData={formData}
					formErrors={formErrors}
					name="taxCode"
					handleChange={handleChange}
					/>
				</Grid>

				<Grid item xs={12}>
					<FieldText
					label="Cognome e Nome Partecipante*"
					formData={formData}
					formErrors={formErrors}
					name="participant"
					handleChange={handleChange}
					/>
				</Grid>

				<Grid item xs={12}>
					<FieldText
					label="Inserisci il tuo numero di cellulare*"
					formData={formData}
					formErrors={formErrors}
					name="phone"
					handleChange={handleChange}
					/>
				</Grid>

				<Grid item xs={12}>
					<FieldText
					label="Indirizzo Email*"
					formData={formData}
					formErrors={formErrors}
					name="email"
					type="email"
					handleChange={handleChange}
					/>
				</Grid>

				<Grid item xs={12}>
					<FieldRadio
					label="Ufficio Zona di Riferimento*"
					formData={formData}
					formErrors={formErrors}
					name="reference"
					options={referenceOptions}
					handleChange={handleChange}
					/>
				</Grid>

				<Grid item xs={12}>
					<FieldRadio
					label="Partecipo*"
					formData={formData}
					formErrors={formErrors}
					name="attend"
					options={attendOptions}
					handleChange={handleChange}
					/>
				</Grid>

				{/* { formData?.attend === 'all' && (
					<Grid item xs={12}>
					<FieldRadio
						label="Menù*"
						formData={formData}
						formErrors={formErrors}
						name="menu"
						options={menuOptions}
						handleChange={handleChange}
					/>
					</Grid>
				)} */}

				<Grid item xs={12}>
					<FieldAuthorizeData
						formData={formData}
						formErrors={formErrors}
						name="authorizeDataRecord"
						handleChange={handleChange}
					/>
				</Grid>
			</Grid>
		</form>
	)
}