import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Button, Typography } from '@mui/material';
import { submit } from '../services/api';
import Header from './Header'
import Footer from './Footer'
import { FormConferenza } from './FormFields'

export default function CustomerForm() {
  const { token } = useParams();
  const initialState = {
    ragioneSociale: '',
    codiceFiscaleAzienda: '',
    nomePartecipante: '',
    numeroCellulare: '',
    email: '',
    ufficioZonaRiferimento: '',
    partecipoAssemblea: '',
    partecipoCena: '',
    autorizzoDati: false,
  };
  const [formData, setFormData] = useState(initialState);
  const [formErrors, setFormErrors] = useState({});
  const [ submitResult, setSubmitResult ] = useState()

  const handleChange = (event) => {
    const { name, value, checked, type } = event.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault()
    submit(token, formData).then(result => {
      if(result === true) {
        setSubmitResult(true)
        setFormErrors({})
      }
      else {
        setFormErrors(result)
        setSubmitResult(false)
      }
    })
  }
  
  const handleClearForm = () => {
    setFormData(initialState); // Resets the form to its initial state
    setFormErrors({})
  };
  
  return (
    <>
      <Header /> {/* This will render the header at the top */}
      <Container maxWidth="sm"> {/* Controls the max width for adaptive design */}
        {/* <Typography variant='subtitle1'>
          Carissimi Associati ed Amici,<br /><br />
          vi informiamo che abbiamo raggiunto la data limite per accettare nuove richieste di partecipazione alla nostra Assemblea Generale. Pertanto, il modulo per inviare le iscrizioni non è più disponibile.<br />
          Siamo lieti del grande interesse mostrato e desideriamo ringraziarvi per il vostro entusiasmo. Ogni anno il vostro supporto rende questo appuntamento un evento speciale e non vediamo l’ora di viverlo insieme ancora una volta.<br />
          Per qualsiasi domanda o necessità potrete contattare i nostri uffici di zona.
        </Typography> */}
        { submitResult ? (
          <>
            <Typography variant='h5'>Grazie per aver richiesto di partecipare all'assemblea.</Typography>
            <Typography variant='h6'>Riceverai una mail di conferma, prima dell'evento, contenente il qrcode da presentare all’ingresso.</Typography>
          </>
        ) : (
          <FormConferenza
            handleSubmit={handleSubmit}
            formData={formData}
            formErrors={formErrors}
            handleChange={handleChange}
          />
        )}
      </Container>
      <Footer />
      { !submitResult && (
        <Container maxWidth="sm" sx={{ mt: 2, display: 'flex', justifyContent: 'space-between', paddingBottom: 2 }}>
          <Button 
            variant="contained" 
            onClick={handleSubmit}
            sx={{ backgroundColor: 'rgb(0, 123, 60)', color: 'white', '&:hover': { backgroundColor: 'rgb(10, 85, 44)' } }}>Invia</Button>
          <Button 
            variant="outlined" 
            onClick={handleClearForm}
            sx={{ color: 'rgb(0, 123, 60)', borderColor: 'rgb(0, 123, 60)' }}>Cancella modulo</Button>
        </Container>
      )}
    </>
  );
}
